import React from 'react'
import Layout from '@components/layout';
import CareersSlider from '@components/careers-slider'


export default function careers(props) {
  const jobsLink = 'https://www.linkedin.com/jobs/view/3418837529/';

  const heroContent = 'We are committed to make working at WyeWorks such a transformative experience and so enriching for the people that other companies are inspired by how we run our organization.';

  return (
    <Layout location={props.location} title='Careers' namespace='careers' description={heroContent}>
      <div className='hero hero-1'>
        <div className='hero-1__col-1'>
          <div className='title-label-1'>
            <div className='title-label-1__count'>
              06
            </div>
            <div className='title-label-1__seperator'></div>
            <div className='title-label-1__label'>
              Careers
            </div>
          </div>
          <h1 className='hero-1__title'>
            Join Our Team
          </h1>
          <p className='hero-1__content'>
            {heroContent}
          </p>

          <a
            className='primary-button'
            href={jobsLink}
            target="_blank"
            rel="noopener noreferrer"
          >
            Apply Now
            <div className='primary-button__icon'>
              <div className='icon-right-arrow-icon'></div>
            </div>
          </a>
        </div>
        <div className='hero-1__hero-image hero-1__hero-image--services'>
          <div className='hero-1__image-wrapper'>
            <div className='hero-1__image hero-1__image--services'>
              <img src='/images/our-team.jpg' alt='our team' />
            </div>
            <div className='hero-1__bg-pattern hero-1__bg-pattern--services'>
              <img src='/images/hero-services-pattern.png' alt='' />
            </div>
          </div>
        </div>
        <div className='hero-1__col-2'></div>
      </div>
      <section className='careers-introduction'>
        <div className='content-block-1'>
          <div className='content-block-1__title-label'>
            <h2 className='h2-label'>
              <div className='h2-label__title'>
                Our Team
              </div>
              <div className='h2-label__seperator'></div>
            </h2>
          </div>
          <div className='content-block-1__content'>
            <h3>
              Great teammates help run our organization
            </h3>
            <p>
              A great teammate will become a guardian of our values, has the potential to develop great skills, and furthermore, has the right attitude. We want a teammate that contributes to building the company not just limited to software projects.
            </p>
          </div>
        </div>
        <div className='content-slider-2'>
          <CareersSlider />
          <div className='content-slider-2__left-image'>
            <img src='/images/careers-play.jpg' alt='' />
          </div>
          <div className='content-slider-2__left-pattern'>
            <img src='/images/careers-play-pattern.png' alt='' />
          </div>
          <div className='content-slider-2__right-image'>
            <img src='/images/careers-working.jpg' alt='' />
          </div>
          <div className='content-slider-2__right-pattern'>
            <img src='/images/careers-work-pattern.png' alt='' />
          </div>
        </div>
      </section>
      <section className='benefits'>
        <div className='content-block-2'>
          <div className='content-block-2__title-label'>
            <h2 className='h2-label'>
              <div className='h2-label__title'>
                Our Benefits
              </div>
              <div className='h2-label__seperator'></div>
            </h2>
          </div>
          <div className='content-block-2__content'>
            <h3>
              We love to take care of our family
            </h3>
            <p>
              While there’s a lot we do as an organization for our mental and physical well-being, we also have some treats that contribute to our goal of making work feel enriching, energizing, and fun.
            </p>
          </div>
        </div>
        <div className='benefits__wrapper'>
          <div className='benefit__card benefit__card--flexibility'>
            <div className='benefit__card-content'>
              <div className='benefit__card-icon'>
                <div className='icon-time-icon'></div>
              </div>
              <h5 className='benefit__card-title'>
                Flexibility &amp; Autonomy
              </h5>
              <div className='benefit__card-description'>
                Set your own work schedule, work remotely and take time off to study.
              </div>
            </div>
          </div>
          <div className='benefit__card benefit__card--language'>
            <div className='benefit__card-content'>
              <div className='benefit__card-icon'>
                <div className='icon-language-icon'></div>
              </div>
              <h5 className='benefit__card-title'>
                Language
              </h5>
              <div className='benefit__card-description'>
                Improve your language abilities with our english lessons tailored to your needs.
              </div>
            </div>
          </div>
          <div className='benefit__card benefit__card--labs'>
            <div className='benefit__card-content'>
              <div className='benefit__card-icon'>
                <div className='icon-lab-icon'></div>
              </div>
              <h5 className='benefit__card-title'>
                Labs
              </h5>
              <div className='benefit__card-description'>
                Study and experiment in our lab time and share with your teammates what you’ve learned!
              </div>
            </div>
          </div>
          <div className='benefit__card benefit__card--food'>
            <div className='benefit__card-content'>
              <div className='benefit__card-icon'>
                <div className='icon-snacks-icon'></div>
              </div>
              <h5 className='benefit__card-title'>
                Food &amp; Snacks
              </h5>
              <div className='benefit__card-description'>
                Don’t worry about cooking the day before or hurrying out of work to find food, we got you covered!
              </div>
            </div>
          </div>
          <div className='benefit__card benefit__card--travel'>
            <div className='benefit__card-content'>
              <div className='benefit__card-icon'>
                <div className='icon-travel-icon'></div>
              </div>
              <h5 className='benefit__card-title'>
                Travel
              </h5>
              <div className='benefit__card-description'>
                Take the opportunity to travel to US to attend conferences or visit clients every year.
              </div>
            </div>
          </div>
          <div className='benefit__card benefit__card--massages'>
            <div className='benefit__card-content'>
              <div className='benefit__card-icon'>
                <div className='icon-spa-icon'></div>
              </div>
              <h5 className='benefit__card-title'>
                Massages
              </h5>
              <div className='benefit__card-description'>
                Enjoy our relaxation massages to free from stress and have time to re-energize.
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className='career-listings'>
        <h3 className='career-listings__title'>
          Come build great things with us
        </h3>
        <ul className='career-listings__list'>
          <li className='career-listings__list-item'>
            <div className='career-listings__container'>
              <div className='career-listings__info'>
                <h5 className='career-listings__position-title'>
                  Software Engineer
                </h5>
                <div className='career-listings__position-location'>
                  Montevideo, Uruguay
                </div>
              </div>
              <a className='career-listings__apply-link' href={jobsLink} target='_blank' rel='noopener noreferrer'>
                Apply Now
              </a>
            </div>
          </li>
        </ul>
      </section>
    </Layout>
  )
}
