import React from 'react'
import Slider from 'react-slick'

export default function careersSlider() {
  return (
    <Slider 
      className='content-slider-2__slide'
      slidesToShow={1}
      fade={true}
      dots={true}
      arrows={false}
      centerMode={true}
      appendDots={dots => (
        <div className="content-block-2__nav-tabs nav-tabs">
          <ul className="slick-dots">{dots}</ul>
        </div>
      )}
      dotsClass='content-block-2__nav-tabs nav-tabs'
    >
      <div className='content-block-2__wrapper'>
        <div className='content-block-2__label-number'>
          01
        </div>
        <h4 className='content-block-2__title'>
          Our organizational structure is heavily based on self-management
        </h4>
        <p>
          We strongly believe in self-organized teams. Natural and healthy leaderships emerge. Performance, creativity and joy peaks when intrinsic motivation, challenging peers and market demands are aligned to a common purpose.
        </p>
        <p>
          That’s why we have a very simple structure mostly for administrative support functions, with no centralized HR, and management acting as coaches and mentors to remove obstacles, facilitate and keeping all the pieces together.
        </p>
      </div>
      <div className='content-block-2__wrapper'>
        <div className='content-block-2__label-number'>
          02
        </div>
        <h4 className='content-block-2__title'>
          We bring our whole self to the workplace
        </h4>
        <p>
          We want everybody to become the strongest and healthiest version of themselves. We believe we need a workplace that feels safe, where speaking the truth doesn’t feel risky at all, where you can voice your dreams and aspirations and you can honor all your commitments in the different facets of your life.
        </p>
        <p>
          We share moments of collective reflection, one on one conversations about upsets, challenges, worries, offer emotional support and together think how to build a better future.
        </p>
      </div>
      <div className='content-block-2__wrapper'>
        <div className='content-block-2__label-number'>
          03
        </div>
        <h4 className='content-block-2__title'>
          We are building this together
        </h4>
        <p>
          We are driven by our purpose and our purpose evolves as we listen to everybody on the team. We encourage everybody to speak out and contribute with ideas and action to make this the most remarkable working experience ever.
        </p>
        <p>
          We keep everybody on the loop with our biweekly news cycle, regular one on ones, quarterly public objectives, our beloved annual off-site retreat and of course lots of informal conversations over loads of great coffee.
        </p>
      </div>
    </Slider>
  )
}
